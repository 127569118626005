


































import { Component, Vue, Prop } from "vue-property-decorator";
import MainHeader from "../../../main/components/main-header/main-header.vue";
import NavLeft from "../../../main/components/nav-left/nav-left.vue";
import JianyanEdit from "../../components/jianyan/jianyan-edit.vue";
Component.registerHooks([
  "beforeRouteEnter",
  "beforeRouteLeave",
  "beforeRouteUpdate",
]);
@Component({
  components: {
    MainHeader,
    NavLeft,
    JianyanEdit,
  },
})
export default class Name extends Vue {
  private routerPath: any = "";
  private addData: any = {};
  private id: any = "";
  private queryData: any = {};
  private ifShowSaveConfirm: any = false;
  private noSaveText: any = "不保存，离开";

  private get patient() {
    return this.$store.state.patient;
  }
  private get ifCompose() {
    return this.$store.state.ifCompose;
  }

  /**
   * @description 离开页面前保存
   */
  private beforeRouteLeave(to: any, from: any, next: any) {
    this.routerPath = to.fullPath;
    if (this.ifCompose) {
      this.ifShowSaveConfirm = true;
      next(false);
    } else {
      next(true);
    }
  }
  private mounted() {
    if (this.$route.query.data) {
      this.queryData = JSON.parse(this.$route.query.data as any);
    }
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
    }
  }
}
